import axios from "axios";

export default {
  getUser() {
    return axios.get("/account/");
  },

  registerUser(payload) {
    return axios.post("/account/registration/", payload);
  },

  loginUser(user) {
    return axios.post("/account/login/", user, {
      credentials: "include",
    });
  },
  logout() {
    return axios.get("/account/logout/");
  },
  initRestore(payload) {
    return axios.post("/account/restore/", payload);
  },
  commitRestore(payload) {
    return axios.post("account/restore/password/", payload);
  },
};
