import Vue from "vue";
import Vuex from "vuex";

import timers from "./modules/timers";
import auth from "./modules/auth";
import bindings from "./modules/bindings";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    timers,
    auth,
    bindings,
  },
});
