import bindingService from "@/services/binding-service";
import timerService from "@/services/timer-service";

const getters = {
  allBindings: (state) => state.bindings,
  binding: (state) => state.binding,
};

const actions = {
  getBindings({ commit }) {
    commit("SET_ERRORS", null);

    bindingService
      .getAllBindings()
      .then((response) => {
        if (response.status === 200) {
          commit("SET_BINDINGS", response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          commit("SET_ERRORS", error.response.data);
        }
      });
  },

  async getBinding({ commit }, id) {
    commit("SET_ERRORS", null);

    await bindingService
      .getBindins(id)
      .then((response) => {
        if (response.status === 200) {
          commit("SET_BINDING", response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          commit("SET_ERRORS", error.response.data);
        }
      });
  },

  async getBindingsByTimerId({ commit }, timerid) {
    commit("SET_ERRORS", null);

    await timerService
      .getTimerBindings(timerid)
      .then((response) => {
        if (response.status === 200) {
          commit("SET_BINDING", response.data[0]);
        }
      })
      .catch((error) => {
        if (error.response) {
          commit("SET_ERRORS", error.response.data);
        }
      });
  },

  removeBinding({ commit }, id) {
    commit("SET_ERRORS", null);

    bindingService
      .removeBinding(id)
      .then((response) => {
        if (response.status === 204) {
          commit("SET_BINDING", {});
          commit("DROP_FROM_BINDINS", id);
        }
      })
      .catch((error) => {
        if (error.response) {
          commit("SET_ERRORS", error.response.data);
        }
      });
  },

  async exportTimer({ commit }, payload) {
    commit("SET_ERRORS", null);

    await bindingService
      .exportTimer(payload)
      .then((response) => {
        if (response.status === 201) {
          commit("SET_BINDING", response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          commit("SET_ERRORS", error.response.data);
        }
      });
  },
};

const mutations = {
  SET_BINDINGS(state, bindings) {
    Object.assign(state, { bindings });
  },
  SET_BINDING(state, binding) {
    Object.assign(state, { binding });
  },
  SET_ERRORS(state, errors) {
    Object.assign(state, errors);
  },
  RESET(state) {
    Object.assign(state, defaultState());
  },
  DROP_FROM_BINDINS(state, bindingId) {
    state.bindings = state.bindings.filter((el) => el.id !== bindingId);
  },
};

const defaultState = () => ({
  bindings: [],
  binding: {},
  errors: [],
});

const state = () => ({
  bindings: [],
  binding: {},
  errors: [],
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
