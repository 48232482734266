import timerService from "@/services/timer-service";

const getters = {
  dimensions: (state) => state.dimensions,
  allTimers: (state) => state.timers,
  timer: (state) => state.timer,
  errors: (state) => state.errors,
  isLoading: (state) => state.isLoading,
};

const actions = {
  getTimers({ commit }) {
    commit("SET_ERRORS", null);
    commit("SET_LOADING", true);

    timerService
      .getAllTimers()
      .then((response) => {
        if (response.status === 200) {
          commit("SET_TIMERS", response.data);
          commit("SET_LOADING", false);
        }
      })
      .catch((error) => {
        commit("SET_ERRORS", error.message);
        commit("SET_LOADING", false);
      });
  },

  getTimer({ commit }, id) {
    commit("SET_ERRORS", null);

    timerService
      .getTimer(id)
      .then((response) => {
        if (response.status === 200) {
          commit("SET_TIMER", response.data);
          console.log("getTimer", response.data);
        }
      })
      .catch((error) => {
        commit("SET_ERRORS", error.message);
      });
  },

  async create({ commit }, timer) {
    commit("SET_ERRORS", null);
    console.log("createTimer req", timer);
    await timerService
      .createTimer(timer)
      .then((response) => {
        if (response.status === 201) {
          commit("SET_TIMER", response.data);
          console.log("createTimer res", response.data);
        }
      })
      .catch((error) => {
        commit("SET_ERRORS", error);
      });
  },

  async updateTimer({ commit }, timer) {
    commit("SET_ERRORS", null);
    console.log("updateTimer req", timer);
    await timerService
      .updateTimer(timer)
      .then((response) => {
        if (response.status === 200) {
          commit("SET_TIMER", response.data);
          console.log("updateTimer res", response.data);
        }
      })
      .catch((error) => {
        commit("SET_ERRORS", error.message);
      });
  },

  async removeTimer({ commit }, id) {
    commit("SET_ERRORS", null);

    await timerService
      .deleteTimer(id)
      .then((response) => {
        if (response.status === 204) {
          commit("SET_TIMER", {});
        }
      })
      .catch((error) => {
        commit("SET_ERRORS", error.message);
      });
  },

  reset({ commit }) {
    commit("RESET");
  },

  setDimensions({ commit }, dimensions) {
    commit("SET_DIMENSIONS", dimensions);
  },
};

const mutations = {
  SET_TIMERS(state, timers) {
    Object.assign(state, { timers });
  },
  SET_TIMER(state, timer) {
    Object.assign(state, { timer });
  },
  UPDATE_TIMER(state, timer) {
    state.timer = Object.assign({}, state.timer, timer);
  },
  SET_ERRORS(state, errors) {
    Object.assign(state, { errors });
  },
  SET_ERROR(state, error) {
    Object.assign(state, { error });
  },
  RESET(state) {
    Object.assign(state, defaultState());
  },
  DROP_FROM_TIMERS(state, timerId) {
    state.timers = state.timers.filter((el) => el.id !== timerId);
  },
  SET_DIMENSIONS(state, dimensions) {
    state.dimensions = Object.assign({}, state.dimensions, dimensions);
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
};

const defaultState = () => ({
  dimensions: {},
  isLoading: false,
  timers: [],
  timer: {},
  errors: [],
  error: null,
});

const state = () => ({
  dimensions: {},
  isLoading: false,
  timers: [],
  timer: {},
  errors: [],
  error: null,
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
