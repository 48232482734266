import auth from "@/services/auth-service";

const getters = {
  currentUser: (state) => state.currentUser,
  withFacebook: (state) => state.currentUser.oauth2.facebook,
  withGoogle: (state) => state.currentUser.oauth2.google,
  errors: (state) => state.errors,
  isAuthenticated: (state) =>
    state.currentUser && state.currentUser.is_authenticated,
  isLoading: (state) => state.isLoading,
};

const actions = {
  getUser({ commit }) {
    commit("SET_ERRORS", null);

    auth
      .getUser()
      .then((response) => {
        if (response.status === 200) {
          commit("SET_CURRENTUSER", response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          commit("SET_ERRORS", error.response.data);
        }
      });
  },

  async createUser({ commit }, payload) {
    commit("SET_ERRORS", null);
    commit("SET_LOADING", true);

    const user = JSON.stringify(payload);

    await auth
      .registerUser(user)
      .then((response) => {
        if (response.status === 201) {
          commit("SET_CURRENTUSER", response.data);
          commit("SET_LOADING", false);
        }
      })
      .catch((error) => {
        if (error.response) {
          commit("SET_ERRORS", error.response.data);
          commit("SET_LOADING", false);
        }
      });
  },

  async login({ commit }, payload) {
    commit("SET_ERRORS", null);
    commit("SET_LOADING", true);

    const user = JSON.stringify(payload);

    await auth
      .loginUser(user)
      .then((response) => {
        if (response.status === 200) {
          commit("SET_CURRENTUSER", response.data);
          commit("SET_LOADING", false);
        }
      })
      .catch((error) => {
        if (error.response) {
          commit("SET_ERRORS", error.response.data);
          commit("SET_LOADING", false);
        }
      });
  },

  logout({ commit }) {
    commit("SET_ERRORS", null);

    auth
      .logout()
      .then((response) => {
        if (response.status === 200) {
          commit("RESET");
        }
      })
      .catch((error) => {
        if (error.response) {
          commit("SET_ERRORS", error.response.data);
        }
      });
  },

  async initRestore({ commit }, payload) {
    commit("SET_ERRORS", null);

    const email = JSON.stringify(payload);

    await auth
      .initRestore(email)
      .then((response) => {
        if (response.status === 201) {
          commit("SET_LOADING", false);
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          commit("SET_ERRORS", [error.response.statusText]);
          commit("SET_LOADING", false);
        } else {
          commit("SET_ERRORS", error.response.data);
          commit("SET_LOADING", false);
        }
      });
  },

  async commitRestore({ commit }, payload) {
    commit("SET_ERRORS", null);

    const credentials = JSON.stringify(payload);

    await auth
      .commitRestore(credentials)
      .then((response) => {
        if (response.status === 201) {
          commit("SET_LOADING", false);
        }
      })
      .catch((error) => {
        if (error.response) {
          commit("SET_ERRORS", error.response.data);
          commit("SET_LOADING", false);
        }
      });
  },
};

const mutations = {
  SET_CURRENTUSER(state, currentUser) {
    Object.assign(state, { currentUser });
  },
  SET_ERRORS(state, errors) {
    Object.assign(state, { errors });
  },
  RESET(state) {
    Object.assign(state, defaultState());
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
};

const defaultState = () => ({
  currentUser: null,
  errors: [],
});

export default {
  namespaced: true,
  state: {
    currentUser: null,
    errors: [],
    isLoading: false,
  },
  getters,
  actions,
  mutations,
};
