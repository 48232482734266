import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@/assets/css/tailwind.css";

/* Directives */
import clickOutsideDirective from "@/directives/click-outside";
Vue.mixin(clickOutsideDirective);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    if (!store.getters["auth/currentUser"]) {
      store.dispatch("auth/getUser");
    }
  },
}).$mount("#app");
