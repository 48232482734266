<template>
  <div id="app" class="bg-white sm:bg-gray-100 h-screen">
    <navbar></navbar>
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar,
  },
};
</script>
