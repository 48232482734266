import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "EditorPage",
    component: () =>
      import(/* webpackChunkName: "EditorPage" */ "../pages/EditorPage.vue"),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () =>
      import(/* webpackChunkName: "Signup" */ "../pages/auth/Signup.vue"),
  },
  {
    path: "/signin",
    name: "Signin",
    component: () =>
      import(/* webpackChunkName: "Signin" */ "../pages/auth/Signin.vue"),
  },
  {
    path: "/recover",
    name: "Recover",
    component: () =>
      import(/* webpackChunkName: "Recover" */ "../pages/auth/Recover.vue"),
  },
  {
    path: "/recover/password/",
    name: "SubmitPassword",
    component: () =>
      import(
        /* webpackChunkName: "SubmitPassword" */ "../pages/auth/Password.vue"
      ),
  },
  {
    path: "/subscribe/success",
    name: "SubSuccess",
    component: () =>
      import(
        /* webpackChunkName: "SubSuccess" */ "../pages/subs/SubSuccess.vue"
      ),
  },
  {
    path: "/subscribe/error",
    name: "SubError",
    component: () =>
      import(/* webpackChunkName: "SubSuccess" */ "../pages/subs/SubError.vue"),
  },
  {
    path: "/edit/:timerId/",
    name: "Edit",
    component: () =>
      import(/* webpackChunkName: "EditorPage" */ "../pages/EditorPage.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "../pages/DashboardPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account",
    name: "Account",
    component: () =>
      import(/* webpackChunkName: "AccountPage" */ "../pages/AccountPage.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["auth/isAuthenticated"];

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    isAuthenticated ? next() : next("/signin");
  } else {
    next();
  }
});

export default router;
