import axios from "axios";

const BASE_URL = process.env.VUE_APP_API_BASE + "/api-rest/v1";

export default {
  createTimer(payload) {
    return axios.post(BASE_URL + "/timers/", payload);
  },
  getAllTimers() {
    return axios.get(BASE_URL + "/timers/");
  },
  getTimer(id) {
    return axios.get(BASE_URL + `/timers/${id}/`);
  },
  updateTimer(payload) {
    return axios.patch(BASE_URL + `/timers/${payload.id}/`, payload);
  },
  getTimerBindings(timerId) {
    return axios.get(BASE_URL + `/timers/${timerId}/bindings/`);
  },
  deleteTimer(id) {
    return axios.delete(BASE_URL + `/timers/${id}/`);
  },
};
