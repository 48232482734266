import axios from "axios";

const BASE_URL = process.env.VUE_APP_API_BASE + "/api-rest/v1";

export default {
  getAllBindings() {
    return axios.get(BASE_URL + "/bindings/");
  },
  getBinding(bindingId) {
    return axios.get(BASE_URL + `/bindings/${bindingId}/`);
  },
  removeBinding(bindingId) {
    return axios.delete(BASE_URL + `/bindings/${bindingId}/`);
  },
  exportTimer(payload) {
    return axios.post(BASE_URL + `/timers/${payload.timerID}/export_timer/`, {
      email: payload.email,
    });
  },
};
